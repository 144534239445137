<template>
  <form action data-vv-scope="cash-register-form">
    <v-row>
      <v-col cols="12" class="py-0">
        <label>Nombre</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="cash_register.name"
          data-vv-validate-on="blur"
          v-validate="'required'"
          :error-messages="errors.collect('cash-register-form.name')"
          data-vv-name="name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-checkbox
          ocheckbox
          dense
          label="Por defecto"
          hide-details
          v-model="cash_register.default"
          data-vv-validate-on="blur"
          v-validate="''"
          :error-messages="errors.collect('cash-register-form.default')"
          data-vv-name="default"
        ></v-checkbox>
      </v-col>
    </v-row>
  </form>
</template>
<script>
export default {
  name: "FormCashRegister",
  props: {
    cash_register: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async validate() {
      let val = await this.$validator.validateAll("cash-register-form");
      if (val) this.$emit("validate");
    },
  },
};
</script>
<style scoped lang="sass">
label
  display: block
  height: 25px
</style>